import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function regionTypeAllocated (data, field, type) {
  let total = 0
  if (data.length > 0) {
    data.forEach((element1, key) => {
      element1.ware_house.forEach((element1, key) => {
        element1.data.forEach((element1, key) => {
            if (element1.id === field) {
              total += element1[type] !== undefined ? element1[type] : 0
            }
        })
      })
    })
  }
  return total
}
function divisionTypeAllocated (data, field, type) {
  let total = 0
  data.forEach((salesCenter) => {
    salesCenter.sales_center.forEach((element1, key) => {
      element1.ware_house.forEach((element1, key) => {
        element1.data.forEach((element1, key) => {
          if (element1.id === field) {
            total += element1[type]
          }
        })
      })
    })
  })
  return total
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, typeName = '', search, fertilizerNameList) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        if (typeName) {
          pdfContent.push({ text: typeName, style: 'report_type', alignment: 'center' })
        }
        const allRowsHead = []
        if (search.region_name) {
          allRowsHead.push([
            { text: vm.$t('globalTrans.region'), alignment: 'right', style: 'td' },
            { text: ':', alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? search.region_name_bn : search.region_name, alignment: 'left', style: 'td' }
          ])
        }
        allRowsHead.push(
          [
            { text: vm.$t('globalTrans.from_date'), alignment: 'right', style: 'td' },
            { text: ':', alignment: 'center', style: 'td' },
            { text: dateFormat(search.from_date), alignment: 'left', style: 'td' }
          ],
          [
            { text: vm.$t('globalTrans.to_date'), alignment: 'right', style: 'td' },
            { text: ':', alignment: 'center', style: 'td' },
            { text: dateFormat(search.to_date), alignment: 'left', style: 'td' }
          ]
        )
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['48%', '2%', '55%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        const firstRaw = [
          { text: vm.$t('fertilizerReport.region'), style: 'th', alignment: 'center', rowSpan: 2 },
          { text: vm.$t('fertilizerReport.name_of_fertilizer_sale_center_godowns'), style: 'th', alignment: 'center', rowSpan: 2 },
          { text: vm.$t('fertilizerReport.godown'), style: 'th', alignment: 'center', rowSpan: 2 },
          { text: vm.$t('fertilizerReport.godown_capacity'), style: 'th', alignment: 'center', rowSpan: 2 },
          { text: vm.$t('fertilizerReport.fertilzer_alloment'), style: 'th', alignment: 'center', colSpan: fertilizerNameList.length }
        ]
        fertilizerNameList.map((item, index) => {
          firstRaw.push(
            {}
          )
        })
        firstRaw.push(
          { text: vm.$t('fertilizerReport.fertilizer_received'), style: 'th', alignment: 'right', colSpan: fertilizerNameList.length }
        )
        fertilizerNameList.map((item, index) => {
          firstRaw.push(
            {}
          )
        })
        firstRaw.push(
          { text: vm.$t('fertilizerReport.fertilizerSale'), style: 'th', alignment: 'right', colSpan: fertilizerNameList.length }
        )
        fertilizerNameList.map((item, index) => {
          firstRaw.push(
            {}
          )
        })
        firstRaw.push(
          { text: vm.$t('fertilizerReport.balance_stock_on'), style: 'th', alignment: 'right', colSpan: fertilizerNameList.length }
        )
        fertilizerNameList.map((item, index) => {
          firstRaw.push(
            {}
          )
        })
        const allRows = [firstRaw]
        const headList = [
          {},
          {},
          {},
          {}
        ]
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        headList.push(
          { text: vm.$t('fertilizerReport.total'), style: 'th', alignment: 'right' }
        )
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        headList.push(
          { text: vm.$t('fertilizerReport.total'), style: 'th', alignment: 'right' }
        )
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        headList.push(
          { text: vm.$t('fertilizerReport.total'), style: 'th', alignment: 'right' }
        )
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        headList.push(
          { text: vm.$t('fertilizerReport.total'), style: 'th', alignment: 'right' }
        )
        allRows.push(headList)
        data.forEach((division, index) => {
          division.regions.forEach((region) => {
            region.sales_center.forEach((salesCenter, index2) => {
              salesCenter.ware_house.forEach((wareHouse, index3) => {
                const NewLoop = []
                if (index2 === 0 && index3 === 0) {
                  NewLoop.push({ text: (i18n.locale === 'bn') ? region.region_name_bn : region.region_name, style: 'td', rowSpan: region.rowspan, alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                if (index3 === 0) {
                  NewLoop.push({ text: (i18n.locale === 'bn') ? salesCenter.sale_center_name_bn : salesCenter.sale_center_name, style: 'td', rowSpan: salesCenter.rowspan, alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                NewLoop.push(
                  { text: (i18n.locale === 'bn') ? wareHouse.name_bn : wareHouse.name, style: 'td', alignment: 'center' },
                  { text: vm.$n(wareHouse.capacity), style: 'td', alignment: 'center' }
                )
                wareHouse.data.forEach((data, index11) => {
                  NewLoop.push({ text: vm.$n(data.allocated), alignment: 'right', style: 'td' })
                })
                NewLoop.push({ text: vm.$n(wareHouse.total_allocated), alignment: 'right', style: 'td' })
                wareHouse.data.forEach((data, index11) => {
                  NewLoop.push({ text: vm.$n(data.received), alignment: 'right', style: 'td' })
                })
                NewLoop.push({ text: vm.$n(wareHouse.total_received), alignment: 'right', style: 'td' })
                wareHouse.data.forEach((data, index11) => {
                  NewLoop.push({ text: vm.$n(data.sales), alignment: 'right', style: 'td' })
                })
                NewLoop.push({ text: vm.$n(wareHouse.total_sales), alignment: 'right', style: 'td' })
                wareHouse.data.forEach((data, index11) => {
                  NewLoop.push({ text: vm.$n((data.allocated + data.received) - data.sales), alignment: 'right', style: 'td' })
                })
                NewLoop.push({ text: vm.$n(wareHouse.balance), alignment: 'right', style: 'td' })
                allRows.push(NewLoop)
              })
            })
            const newRegList = [
              { text: (i18n.locale === 'bn') ? region.region_name_bn + ' ' + vm.$t('fertilizerReport.total') : region.region_name + ' ' + vm.$t('fertilizerReport.total'), style: 'th', colSpan: 3, alignment: 'right' },
              {},
              {},
              { text: vm.$n(region.capacity), style: 'th', alignment: 'center' }
            ]
            fertilizerNameList.map((item, index) => {
              newRegList.push(
                { text: vm.$n(regionTypeAllocated(region.sales_center, item.value, 'allocated')), style: 'th', alignment: 'right' }
              )
            })
            newRegList.push(
              { text: vm.$n(region.total_allocated), style: 'th', alignment: 'right' }
            )
            fertilizerNameList.map((item, index) => {
              newRegList.push(
                { text: vm.$n(regionTypeAllocated(region.sales_center, item.value, 'received')), style: 'th', alignment: 'right' }
              )
            })
            newRegList.push(
              { text: vm.$n(region.total_received), style: 'th', alignment: 'right' }
            )
            fertilizerNameList.map((item, index) => {
              newRegList.push(
                { text: vm.$n(regionTypeAllocated(region.sales_center, item.value, 'sales')), style: 'th', alignment: 'right' }
              )
            })
            newRegList.push(
              { text: vm.$n(region.total_sales), style: 'th', alignment: 'right' }
            )
            fertilizerNameList.map((item, index) => {
              newRegList.push(
                { text: vm.$n(regionTypeAllocated(region.sales_center, item.value, 'balance')), style: 'th', alignment: 'right' }
              )
            })
            newRegList.push(
              { text: vm.$n(region.total_balance), style: 'th', alignment: 'right' }
            )
            allRows.push(newRegList)
          })
          const neDevList = [
            { text: (i18n.locale === 'bn') ? division.division_name_bn + ' ' + vm.$t('fertilizerReport.total') : division.division_name + ' ' + vm.$t('fertilizerReport.total'), style: 'th', colSpan: 3, alignment: 'right' },
            {},
            {},
            { text: vm.$n(division.capacity), style: 'th', alignment: 'center' }
          ]
          fertilizerNameList.map((item, index) => {
            neDevList.push(
              { text: vm.$n(divisionTypeAllocated(division.regions, item.value, 'allocated')), style: 'th', alignment: 'right' }
            )
          })
          neDevList.push(
            { text: vm.$n(division.total_allocated), style: 'th', alignment: 'right' }
          )
          fertilizerNameList.map((item, index) => {
            neDevList.push(
              { text: vm.$n(divisionTypeAllocated(division.regions, item.value, 'received')), style: 'th', alignment: 'right' }
            )
          })
          neDevList.push(
            { text: vm.$n(division.total_received), style: 'th', alignment: 'right' }
          )
          fertilizerNameList.map((item, index) => {
            neDevList.push(
              { text: vm.$n(divisionTypeAllocated(division.regions, item.value, 'sales')), style: 'th', alignment: 'right' }
            )
          })
          neDevList.push(
            { text: vm.$n(division.total_sales), style: 'th', alignment: 'right' }
          )
          fertilizerNameList.map((item, index) => {
            neDevList.push(
              { text: vm.$n(divisionTypeAllocated(division.regions, item.value, 'sales')), style: 'th', alignment: 'right' }
            )
          })
          neDevList.push(
            { text: vm.$n(division.total_sales), style: 'th', alignment: 'right' }
          )
          allRows.push(neDevList)
        })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 8,
            margin: [0, 0, 0, 0]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 8,
            margin: [0, 0, 0, 0]
          },
          fertilizer: {
            margin: [0, 0, 0, 10]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('imported-fertilizer-stock')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
