<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.import_fertilizer_stock_weekly_sales_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Region"  vid="region_id" >
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="region_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.region') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.region_id"
                        :options="regionList"
                        id="region_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-select>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
               <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="From Date" vid="from_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="from_date"
                      slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.date_from"
                          placeholder="Select Date"
                          id="from_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
             <b-row>
               <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="To Date" vid="to_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="to_date"
                       slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.date_to"
                          placeholder="Select Date"
                          id="to_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.import_fertilizer_stock_weekly_sales_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" @click="pdfExport" class="mr-2">
              {{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row v-show="!loading">
                  <b-col>
                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                      {{ $t('fertilizerReport.import_fertilizer_stock_weekly_sales_report') }}
                    </list-report-head>
                    <div class="text-center">
                      <table style="width:100%;color:black;">
                        <tr v-if="search.region_name">
                          <td align="right" style="width:40%">{{$t('globalTrans.region')}}</td>
                          <td align="center" style="width:5%">:</td>
                          <td align="left" colspan="3">{{ ($i18n.locale === 'bn') ? search.region_name_bn : search.region_name }}</td>
                        </tr>
                        <tr>
                          <td align="right" style="width:40%">{{$t('globalTrans.from_date')}}</td>
                          <td align="center" style="width:5%">:</td>
                          <td align="left" style="width:45%">{{ search.from_date | dateFormat }}</td>
                        </tr>
                        <tr>
                          <td align="right" style="width:40%">{{$t('globalTrans.to_date')}}</td>
                          <td align="center" style="width:5%">:</td>
                          <td align="left" style="width:45%">{{ search.to_date | dateFormat }}</td>
                        </tr>
                      </table>
                    </div>
                  </b-col>
                  </b-row>
                <b-row>
                  <b-col md="12" class="table-responsive mt-3">
                    <b-overlay v-show="showData">
                      <div>
                        <b-table-simple bordered hover small caption-top responsive>
                          <colgroup><col><col></colgroup>
                          <colgroup><col><col><col></colgroup>
                          <colgroup><col><col></colgroup>
                          <b-thead>
                            <b-tr>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.region') }}</b-th>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.name_of_fertilizer_sale_center_godowns') }}</b-th>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.godown') }}</b-th>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.godown_capacity') }}</b-th>
                              <b-th :colspan="fertilizerNameList.length + 1" class="text-center">{{ $t('fertilizerReport.fertilzer_alloment') }}</b-th>
                              <b-th :colspan="fertilizerNameList.length + 1" class="text-center">{{ $t('fertilizerReport.fertilizer_received') }}</b-th>
                              <b-th :colspan="fertilizerNameList.length + 1" class="text-center">{{ $t('fertilizerReport.fertilizerSale') }}</b-th>
                              <b-th :colspan="fertilizerNameList.length + 1" class="text-center">{{ $t('fertilizerReport.balance_stock_on') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'first_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                              <b-th class="text-right">{{ $t('fertilizerReport.total') }}</b-th>
                              <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'second' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                              <b-th class="text-right">{{ $t('fertilizerReport.total') }}</b-th>
                              <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'third' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                              <b-th class="text-right">{{ $t('fertilizerReport.total') }}</b-th>
                              <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'fourth_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                              <b-th class="text-right">{{ $t('fertilizerReport.total') }}</b-th>
                          </b-tr>
                          </b-thead>
                          <b-tbody>
                            <template v-for="(division, index) in checkData">
                              <slot v-for="(region) in division.regions">
                                <slot v-for="(sales_center, index2) in region.sales_center">
                                  <slot v-for="(ware_house, index3) in sales_center.ware_house">
                                    <tr :key="ware_house.id">
                                      <slot v-if="index2 == 0 && index3 == 0">
                                        <td :id="index" :rowspan="region.rowspan" style="vertical-align:middle">{{($i18n.locale === 'bn') ? region.region_name_bn : region.region_name}}</td>
                                      </slot>
                                      <slot v-if="index3 == 0">
                                        <td :id="index2" :rowspan="sales_center.rowspan" style="vertical-align:middle">{{($i18n.locale === 'bn') ? sales_center.sale_center_name_bn : sales_center.sale_center_name}}</td>
                                      </slot>
                                      <td>{{($i18n.locale === 'bn') ? ware_house.name_bn : ware_house.name}}</td>
                                      <td class="text-right">{{$n(ware_house.capacity)}}</td>
                                      <td class="text-right" v-for="(data, index11) in ware_house.data" :key="index11 + '_first'">{{$n(data.allocated)}}</td>
                                      <td class="text-right">{{$n(ware_house.total_allocated)}}</td>
                                      <td class="text-right" v-for="(data, index22) in ware_house.data" :key="index22 + '_second'">{{$n(data.received)}}</td>
                                      <td class="text-right">{{$n(ware_house.total_received)}}</td>
                                      <td class="text-right" v-for="(data, index33) in ware_house.data" :key="index33 + '_third'">{{$n(data.sales)}}</td>
                                      <td class="text-right">{{$n(ware_house.total_sales)}}</td>
                                      <td class="text-right" v-for="(data, index33) in ware_house.data" :key="index33 + '_fourth'">{{$n((data.allocated + data.received)-data.sales)}}</td>
                                      <td class="text-right">{{$n(ware_house.balance)}}</td>
                                    </tr>
                                  </slot>
                                </slot>
                                <tr :key="'R' + region.region_id + division.division_id">
                                  <th style="text-align:right" colspan="3">
                                    {{ ($i18n.locale === 'bn') ? region.region_name_bn : region.region_name }} {{ $t('fertilizerReport.total') }}
                                  </th>
                                  <th style="text-align:right">{{$n(region.capacity)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'last_side' + division.division_id + index">
                                    {{$n(regionTypeAllocated(region.sales_center,item.value,'allocated'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(region.total_allocated)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'last_side1' + division.division_id + index">
                                    {{$n(regionTypeAllocated(region.sales_center,item.value,'received'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(region.total_received)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'last_side2' + division.division_id + index">
                                    {{$n(regionTypeAllocated(region.sales_center,item.value,'sales'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(region.total_sales)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'last_side3' + division.division_id + index">
                                    {{$n(regionTypeAllocated(region.sales_center,item.value,'balance'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(region.total_balance)}}</th>
                                </tr>
                              </slot>
                              <tr :key="'D' + division.division_id">
                                  <th  style="text-align:right"  colspan="3">
                                    {{ ($i18n.locale === 'bn') ? division.division_name_bn : division.division_name }} {{ $t('fertilizerReport.total') }}
                                  </th>
                                  <th style="text-align:right">{{$n(division.capacity)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'allocated' + index">
                                    {{$n(divisionTypeAllocated(division.regions,item.value,'allocated'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(division.total_allocated)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'received' + index">
                                    {{$n(divisionTypeAllocated(division.regions,item.value,'received'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(division.total_received)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'total_received' + index">
                                    {{$n(divisionTypeAllocated(division.regions,item.value,'sales'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(division.total_sales)}}</th>
                                  <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'total_received_data' + index">
                                    {{$n(divisionTypeAllocated(division.regions,item.value,'balance'))}}
                                  </b-th>
                                  <th style="text-align:right">{{$n(division.total_balance)}}</th>
                              </tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, importedFertilizerReport } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import flatpickr from 'flatpickr'
import ListReportHead from '.././ListReportHead.vue'

export default {
  name: 'UiDataTable',
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      regionTable: false,
      withFerlizer: false,
      prev_region_id: false,
      search: {
        region_id: 0,
        region: 0,
        date_from: '',
        date_to: ''
      },
      importedData: [],
      checkData: [],
      spans: [],
      from_date: [],
      date_to: [],
      organization: [],
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    regionList: function () {
       return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    formTitle () {
       return this.$t('fertilizerReport.ministry_allocation_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList
    },
    fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    monthList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    flatpickr('#from_date', {})
    flatpickr('#to_date', {})
    core.index()
  },
  methods: {
    setId (id) {
      this.prev_region_id = id
    },
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.showData = true
      this.getOfficeName(this.search.region_id)
      this.loadData()
    },
    checkSaleCenterSpan (saleCenterIdField) {
      const salesList = this.spans.sales
      const salesObject = salesList.find(sale => sale.sales_center_id === saleCenterIdField)
      return salesObject !== undefined ? salesObject.num_sales : ''
    },
    checkRegion (regionId) {
      const regionList = this.spans.region
      const regionObject = regionList.find(region => region.region_id === regionId)
      return regionObject.num_region
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, importedFertilizerReport, params).then(response => {
        if (response.success) {
          // this.checkData = response.warehouses
          // this.spans = response.spans
          this.checkData = this.getRelationalData(response.warehouses)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getOfficeName (Id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === parseInt(Id))
      if (typeof office === 'undefined') {
        this.search.region_name_bn = ''
        this.search.region_name = ''
      } else {
        this.search.region_name_bn = office.text_bn
        this.search.region_name = office.text_en
      }
    },
    divisionTypeAllocated (data, field, type) {
      let total = 0
      if (data.length > 0) {
        data.forEach((salesCenter) => {
          salesCenter.sales_center.forEach((element1, key) => {
            element1.ware_house.forEach((element1, key) => {
              element1.data.forEach((element1, key) => {
                if (element1.id === field) {
                  total += element1[type] !== undefined ? element1[type] : 0
                }
              })
            })
          })
        })
      }
      return total
    },
    regionTypeAllocated (data, field, type) {
      let total = 0
      if (data.length > 0) {
        data.forEach((element1, key) => {
          element1.ware_house.forEach((element1, key) => {
            element1.data.forEach((element1, key) => {
              if (element1.id === field) {
                // total += element1[type]
                total += element1[type] !== undefined ? element1[type] : 0
              }
            })
          })
        })
      }
      return total
    },
    checkSalesCenter (data) {
      let total = 0
      data.forEach((element1, key) => {
        element1.ware_house.forEach((element1, key) => {
          total++
        })
      })
      return total
    },
    checkWareHouse (data) {
      let total = 0
      data.forEach((element1, key) => {
        total++
      })
      return total
    },
    getRelationalData (data) {
      const regionLists = this.$store.state.commonObj.officeList
      const allDatas = []
      data.forEach((division, divisionkey) => {
        const divisionList = this.$store.state.commonObj.divisionList.find(regionList => regionList.value === division.division_id)
        division.division_name = divisionList !== undefined ? divisionList.text_en : ''
        division.division_name_bn = divisionList !== undefined ? divisionList.text_bn : ''
        let divisionCapacity = 0
        let divisionTotalAllocated = 0
        let divisionTotalSales = 0
        let divisionTotalRecived = 0
        let divisionTotalBalance = 0
        division.regions.forEach((element, key) => {
          const regionObject = regionLists.find(regionList => regionList.value === element.region_id)
          element.region_name = regionObject !== undefined ? regionObject.text_en : ''
          element.region_name_bn = regionObject !== undefined ? regionObject.text_bn : ''
          element.rowspan = this.checkSalesCenter(element.sales_center)
          let regionCapacity = 0
          let regionTotalAllocated = 0
          let regionTotalSales = 0
          let regionTotalRecived = 0
          let regionTotalBalance = 0
          element.sales_center.forEach((element2, key2) => {
            const regionObjects = regionLists.find(regionListd => regionListd.value === element2.sales_center_id)
            element2.sale_center_name = regionObjects !== undefined ? regionObjects.text_en : ''
            element2.sale_center_name_bn = regionObjects !== undefined ? regionObjects.text_bn : ''
            element2.rowspan = this.checkWareHouse(element2.ware_house)
            element2.ware_house.forEach((element3, key3) => {
              regionCapacity += element3.capacity
              let totalAllocated = 0
              let totalSales = 0
              let totalRecived = 0
              let totalBalance = 0
              element3.data.forEach((element4, key) => {
                totalAllocated += element4.allocated
                totalSales += element4.sales
                const received = (element4.lori_received + element4.inter_received) - element4.inter_delivered
                element4.received = received
                totalRecived += received
                totalBalance = (totalAllocated + totalRecived) - totalSales
              })
              element3.total_allocated = totalAllocated
              element3.total_sales = totalSales
              element3.total_received = totalRecived
              element3.balance = totalBalance
              regionTotalAllocated += element3.total_allocated
              regionTotalSales += element3.total_sales
              regionTotalRecived += element3.total_received
              regionTotalBalance += element3.balance
            })
          })
          element.capacity = regionCapacity
          element.total_allocated = regionTotalAllocated
          element.total_sales = regionTotalSales
          element.total_received = regionTotalRecived
          element.total_balance = regionTotalBalance

          divisionTotalAllocated += element.total_allocated
          divisionTotalSales += element.total_sales
          divisionTotalRecived += element.total_received
          divisionTotalBalance += element.total_balance
          divisionCapacity += element.capacity
        })
        division.capacity = divisionCapacity
        division.total_allocated = divisionTotalAllocated
        division.total_sales = divisionTotalSales
        division.total_received = divisionTotalRecived
        division.total_balance = divisionTotalBalance
        allDatas[divisionkey] = division
      })
      return allDatas
    },
    pdfExport () {
      const reportTypeTitle = ''
      const reportTitle = this.$t('fertilizerReport.import_fertilizer_stock_weekly_sales_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.checkData, this, reportTypeTitle, this.search, this.fertilizerNameList)
    },
    getPdfData () {
      const keys = [
          { key: 'date' },
          { key: 'opening' },
          { key: 'opening' },
          { key: 'according_to_received_report' },
          { key: 'delivered_total' },
          { key: 'delivered_total' },
          { key: 'received_total' },
          { key: 'received_total' },
          { key: 'difference_total' },
          { key: 'difference_total' },
          { key: 'according_to_sales_report' },
          { key: 'sold' },
          { key: 'sold' },
          { key: 'inter_delivered_from' },
          { key: 'inter_delivered_from' },
          { key: 'final_stock' },
          { key: 'final_stock' },
          { key: '' }
        ]
      var date = 0
      const listData = this.stockDetailsData.map((item, key) => {
          date += 1
          const rowData = keys.map(keyItem => {
             if (keyItem.key === 'date') {
                return { text: this.$n(date) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
